import React from "react";
import { Flex } from "@chakra-ui/react";
// import SectionTitle from '../../section-title'
import Item from "./item";
import projects from "../../data/portfolio";
import { Box, Heading, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react";

// import projects from '../../../data/en/portfolio'

const Portfolio = () => {
  return (
    <Flex as="section" id="portfolio" flexDir="column">
      
      <Heading size={"xl"} textAlign={"left"} color={"#fff"}>
        Projects
      </Heading>
      <Text color="#fff">Here are some of my projects</Text>
      {/* <p className="font-light text-gray-400">Here are some of my projects</p> */}

      <Flex flexWrap="wrap" justifyContent="start" mx="auto" width={{  }} className="mt-4">
        {projects.map((project) => (
          <Item
            key={project.name}
            name={project.name}
            description={project.description}
            imgSource={project.imgSource}
            skills={project.skills}
            websiteUrl={project.websiteUrl}
            githubUrl={project.githubUrl}
            entities={project.entities}
            playStoreUrl={project.playStoreUrl}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default Portfolio;
