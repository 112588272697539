import React, { useState } from "react";
import { Box, Flex, Heading, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from "chart.js";
import { Radar } from "react-chartjs-2";
import { SkillItem } from "../SkillItem";

// type skillsProps = {
//   skills: Skill[];
//   mainSkills: MainSkill[];
// };

const dataTemplate = {
  labels: [],
  datasets: [
    {
      label: "", // label to display as the legend of the chart, it'll be also displayed in the tooltip
      data: [],
      backgroundColor: "rgba(34, 202, 236, .1)",
      borderColor: "rgba(34, 202, 236, 1)",
    },
  ],
};

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export const Skills = (props) => {
  const { skills, mainSkills } = props;

  return (
    <div className="">
      <Heading size={"xl"} textAlign={"left"} color={"#fff"}>
        Skills
      </Heading>
      <Text color="#fff">
        Technology I&apos;ve worked
      </Text>

      <Stack mt={5} spacing={10} direction={{ base: "column", sm: "row" }} justifyContent={"start"}  width={{ sm: "100%"}}>
        <Box>
          
          {/* <SimpleGrid columns={{ base: 2, sm: 7 }} spacing="6" p="5" pl={{ base: 5, sm: 0 }} textAlign="center" alignSelf={"start"}>
            {skills.map((skill, index) => {
              return <SkillItem key={index} name={skill.name} Icon={skill.Icon} dabbled={skill.dabbled} />;
            })}
          </SimpleGrid> */}

            <Flex flexWrap="wrap" justifyContent="start" mx="auto" width={{sm: "100%", md: "100%", lg: "100%" }} className="mt-4">

              {skills.map((skill, index) => {
                return <SkillItem key={index} name={skill.name} Icon={skill.Icon} dabbled={skill.dabbled} />;
              })}
            </Flex>
        </Box>
        
      </Stack>
    </div>
  );
};
