
import messenger from '../assets/messenger-app2.jpeg'
import api from "../assets/admin-api.jpeg"
import buscaHerois from "../assets/hero2.jpeg"
import todo from "../assets/todo.jpeg"

const projects = [
  {
    name: "Messenger",
    description: "Fullstack real-time messaging app, fully responsive, multi-user, with OAuth.",
    imgSource: messenger,
    skills: ["Next.js", "Tailwind", "Prisma", "Typescript", "MongoDb", "Pusher"],
    websiteUrl: "https://messenger-app-next-one.vercel.app/",
    githubUrl: "https://github.com/rodolfo-code/messenger-app-next",
  },
  {
    name: "Video Catalog Microservice",
    description: "Microservice referring to the Video Catalog Admin using C#, .NET, EntityFramework, Clean Architecture, DDD, TDD with xUnit",
    imgSource: api,
    skills: ["C#", ".NET", "Entity Framework", "TDD", "Clean Architecture"],
    websiteUrl: "",
    githubUrl: "https://github.com/rodolfo-code/admin-catalogo-de-videos-c-sharp",
  },
  {
    name: "Hero search app using NextJs",
    description: "Hero search app using Marvel API, developed in NextJs and Marvel API.",
    websiteUrl: "https://busca-de-herois.vercel.app/",
    imgSource: buscaHerois,
    skills: ["Next.Js", "Styled-components", "Typescript", "Marvel API."],
    githubUrl: "https://github.com/rodolfo-code/busca_de_herois/tree/main",
  },



  {
    name: "Rotion App",
    description: "Blog using GraphCMS to manege content and posts. Made with Next.js and Chakra UI.",
    imgSource: todo,
    skills: ["Next.js", "Tailwind", "Typescript", ],
    websiteUrl: "https://blogcms.vercel.app/",
    githubUrl: "https://github.com/rodolfo-code/rotion-app",
  },
  {
    name: "Api Rotion App",
    description: "Simple and Beautiful app to help RPG players. With the features like RPG Dice Roller, Random Number Generator and Coin Flipper.",
    imgSource: "/images/projects/ramdomapp-min.png",
    skills: ["C#", ".NET", "Entity Framework", "DDD", "TDD", "XUnit"],
    entities: "https://drawsql.app/teams/oliver-8/diagrams/rotion-app",
    // playStoreUrl: "https://play.google.com/store/apps/details?id=com.rafagoulartb.randomapp",
    githubUrl: "https://github.com/rodolfo-code/Api.RotionApp",
  },
  
];

export default projects;
