import './App.css';
import {useEffect} from 'react'
import Navbar from './components/Navbar';
import Hiro from './components/Hiro';
// import Skills from './components/Skills'
import Honors from './components/Honors';
import Certs from './components/Certs';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Projects from './components/Projects';
import { mainSkills, skills } from './data/skills';
import { Skills } from './components/Skills';
// import { Box, Container } from '@chakra-ui/layout';
// import { skills, mainSkills } from "../../data/skills";
import { Text, Box, Container, useMediaQuery, Link } from "@chakra-ui/react";
import Portfolio from './components/portfolio';




function App() {
    useEffect(() => {
      document.title = 'Rodolfo Oliveira';
      AOS.init();
    }, []);
  return (
    // <div className="px-6 lg:px-20 xl:px-36 bg-dark-500">
    <div className="px-6 lg:px-20 xl:px-36 bg-[#1a202c]">
        <Navbar />
        <Hiro />
        <div className='pb-20 lg:pb-40 flex justify-center mb-0 mt-32'>
            <hr className="md:h-1 text-white bottom-0 w-[610px] border-gray-400" />
        </div>
        {/* <Skills /> */}
        {/* <Container maxW="6xl" centerContent mx={0} p={0}>
          <Box id="skills" width={"100%"} mt={12} mx={0}>
            <Skills skills={skills} mainSkills={mainSkills} />
          </Box>
        </Container> */}
            <Skills skills={skills} mainSkills={mainSkills} />
        <div className='pb-20 pt-16 lg:pt-36 lg:pb-40 flex justify-center mb-0 mt-32'>
            <hr className="md:h-1 text-white bottom-0 w-[610px] border-gray-400" />
        </div>
        {/* <Projects /> */}
        <Portfolio />
        <div className='pb-20 pt-20 lg:pt-36  flex justify-center mb-0 mt-32'>
            <hr className="md:h-1 text-white bottom-0 w-[610px] border-gray-400" />
        </div>
        {/* <Honors /> */}
        <Certs />
        <div className='flex justify-center mb-0 mt-32'>
            <hr className="md:h-1 text-white bottom-0 w-[610px] border-gray-400" />
        </div>
        <Footer />
      
    </div>
  );
}

export default App;
