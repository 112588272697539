import letraR from '../assets/letraR.png';
import resume from "../assets/resume.pdf"
import { Link } from "@chakra-ui/react";
import { motion } from "framer-motion";

import { HStack, Text } from "@chakra-ui/react";

export default function Navbar() {
    return (
    <div className='fixed z-50 bg-[#1A202C] w-full top-0 left-0 px-8 py-2 lg:px-20 xl:px-36 shadow-xl'>
        <div className="flex justify-between items-center text-white">
            <img src={letraR} className="w-[55px]" alt="logo" />
            <ul className="hidden md:flex">
                <li className="p-4"><a href="#home" className="hover:underline">About</a></li>
                <li className="p-4"><a href="#skills" className="hover:underline">Skills</a></li>
                <li className="p-4"><a href="#projects" className="hover:underline">Projects</a></li>
                <li className="p-4"><a href="#certs" className="hover:underline">Certfications</a></li>
            </ul>

            <motion.div
              whileHover={{
                scale: 1.1
              }}
              whileTap={{ scale: 0.9 }}
            >
              <a href={resume} target='_blank' className='w-[138px] bg-[#597ba1]'>
                <HStack width={"130px"} boxShadow="md" p="1.5" rounded="md" className='flex justify-center bg-[#597ba1]'>
                  <Text color={"#fff"}>Resume</Text>
                </HStack>
              </a>
              {/* <Link href={resume} className=" bg-teal-500 rounded-full px-4 py-1 border-teal-800 border-4">
                Resume
                <HStack width={"138px"} boxShadow="md" p="1.5" rounded="md">
                  <Text color={"#fff"}>Resume</Text>
                </HStack>
              </Link> */}
            </motion.div>
        </div>
    </div>
    )
}


