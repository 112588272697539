import CertCard from "./CertCard"

// import hr from "../assets/curve-hr.svg"
// import mtcna from "../assets/certs/mtcna.jpg"
// import adinusa from "../assets/certs/adinusa.png"
// import dicodingAws from "../assets/certs/dicoding-aws.png"
// import dicodingJs from "../assets/certs/dicoding-js.png"
// import dicodingNetwork from "../assets/certs/dicoding-network.png"
// import progateHtml from "../assets/certs/progate-htmlcss.png"
// import progateJs from "../assets/certs/progate-js.png"
// import fccResponsive from "../assets/certs/fcc-responsive.png"

export default function Certs(){
    const fundamentalsImg = "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/69611721";
    const fundamentalsRedirect = "https://www.credential.net/f712981c-152b-4dc5-a117-0beba85fc913";

    const frontendImg = "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/69614188";
    const frontendRedirect = "https://www.credential.net/1a28c562-39ae-4eb6-8b0a-4f09511b675b";

    const backendImg = "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/69616669";
    const backendRedirect = "https://www.credential.net/b8f4dfda-1929-4e12-9e10-9b3e03d01d11";

    const computerScienceImg = "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/69621372";
    const computerScienceRedirect = "https://www.credential.net/a9cbcc28-9e95-45b3-8273-5987e624b5d1";

    const fullStackImg = "https://api.accredible.com/v1/frontend/credential_website_embed_image/certificate/69620814";
    const fullStackRedirect = "https://www.credential.net/df8d1748-fd28-4c8b-910d-128a0e9ed091";
    
    return (
        <div id="certs" className="mt-4 py-[70px] text-white">
            <h1 className="text-2xl font-bold">Certifications</h1>
            <p className="font-light text-gray-400">Here are my Certifications</p>

            {/* <div className="flex flex-col md:flex-row flex-wrap mt-4 gap-5"> */}
            <div className="grid grid-cols-1 md:grid-cols-3 justify-center mt-4 gap-5">
                <CertCard name="Fundamentals of Web Development" img={fundamentalsImg} redirect={fundamentalsRedirect} issued="Trybe" date="Oct  2020" />
                <CertCard name="Front-End" img={frontendImg} redirect={frontendRedirect} issued="Trybe" date="May 2021" />
                <CertCard name="Back-End" img={backendImg} redirect={backendRedirect} issued="Trybe" date="Dec 2021" />
                <CertCard name="Computer Science" img={computerScienceImg} redirect={computerScienceRedirect} issued="Trybe" date="March 2022" />
                <CertCard name="Full Stack Web Development" img={fullStackImg} redirect={fullStackRedirect} issued="Trybe" date="March 2022" />
            </div>
            {/* <img src={hr} className="w-full mt-8 md:h-2" alt="hr" /> */}

            {/* <div className='flex justify-center mb-0 mt-32'>
                <hr className="md:h-1 text-white bottom-0 w-[610px] border-gray-400" />
            </div> */}
        </div>
    )
}
