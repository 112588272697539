// import hr from '../assets/curve-hr.svg'

export default function Footer(){
  return (
    <div className="mt-4 bg-dark-200  rounded-md text-white px-8 py-4 mt-[70px]">
		<ul className="text-center">
			<li><a href="#home" className="hover:underline">About</a></li>
			<li><a href="#skills" className="hover:underline">Skills</a></li>
			<li><a href="#honors" className="hover:underline">Projects</a></li>
			<li><a href="#certs" className="hover:underline">Certfications</a></li>
		</ul>

		{/* <img src={hr} className="mb-4 mt-4 w-full md:h-1 text-white bottom-0" alt="hr" /> */}
		<div className='flex justify-center'>
			<hr className="mb-4 mt-4 md:h-1 text-white bottom-0 w-[100px] border-gray-400" />
		</div>

		<p className="text-sm font-normal text-center ">
			Developed by Rodolfo Oliveira.
   			Built with <b className="text-[#14b8a6] font-bold">React.js</b> & <b className="text-[#14b8a6] font-bold">Tailwind</b>. Hosted on Vercel.
		</p>
    </div>
  )
}
